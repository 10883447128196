































import { ToastHelper } from '@/shared/helpers/toast.helper';
import dayjs from 'dayjs';
import { Vue, Component }  from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { CustomFieldAnswer, CustomFieldType } from '../../models/custom-field-answer.model';
import { CustomFieldGroup } from '../../models/custom-field-group.model';
import EditCustomFieldValueModal from './EditCustomFieldValueModal.vue';

@Component
export default class CustomFields extends Vue {

    @Getter
    customFieldGroups!: CustomFieldGroup[];

    @Getter
    customFieldAnswers!: CustomFieldAnswer[];

    get groups() : CustomFieldGroup[] {
        return this.customFieldGroups.sort((x, y) => {
            return x.order - y.order;
        });
    }

    answersInGroup(groupId: string) : CustomFieldAnswer[] {
        return this.customFieldAnswers
            .filter(x => x.customFieldGroupId === groupId)
            .sort((x, y) => {
                return x.customFieldOrder - y.customFieldOrder;
            });
    }

    getDisplayValue(answer: CustomFieldAnswer): string {
        if (!answer.valueAsString)
        {
            return '---';
        }

        switch (answer.customFieldType)
        {
            case CustomFieldType.Bool:
                return answer.boolValue ? `${this.$t('profile.customFields.yes')}` : `${this.$t('profile.customFields.no')}`;

            case CustomFieldType.Date:
                return dayjs(answer.datetimeValue).format('D-M-YYYY');

            default:
                return answer.valueAsString;
        }
    }

    showEditModal(answer: CustomFieldAnswer) : void {
        this.$modal.show(EditCustomFieldValueModal, { 
            field: answer }, { 
                'shiftY': 0.1
            }, { 
                'before-open': this.onModalOpen,
                'before-close': this.onModalClose 
            }
        );
    }

    onModalOpen() : void {
        document.body.classList.add("modal-open");
    }

    onModalClose(modalParams: any) : void {
        if (modalParams?.params?.success === true) {
            ToastHelper.showSuccess(this.$bvToast, this.$t('profile.customFields.editSuccess'));
        }

        document.body.classList.remove("modal-open");
    }
}
