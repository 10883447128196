var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "multiselect",
    {
      attrs: {
        "track-by": "guid",
        label: "name",
        options: _vm.options,
        "open-direction": "bottom",
        placeholder: _vm.$t("profile.customFields.selectOption"),
        multiple: false,
        searchable: false,
        "internal-search": false,
        "max-height": 600,
        "clear-on-select": false,
        "show-no-results": false,
        "show-no-options": false,
        "show-labels": false,
      },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c("template", { slot: "noResult" }, [
        _c("span", { staticClass: "multiselect__single" }, [
          _vm._v(_vm._s(_vm.$t("profile.customFields.noResults"))),
        ]),
      ]),
      _c("template", { slot: "noOptions" }, [
        _c("span", { staticClass: "multiselect__single" }, [
          _vm._v(_vm._s(_vm.$t("profile.customFields.noOptions"))),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }