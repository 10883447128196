var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-12 row m-0 p-0 mb-3" }, [
    _vm.loading
      ? _c(
          "div",
          { staticClass: "loading" },
          [_c("b-spinner", { attrs: { variant: "primary" } })],
          1
        )
      : _vm._e(),
    _c("h6", { staticClass: "col-12 font-weight-bold pt-3" }, [
      _vm._v(_vm._s(_vm.field.customFieldTitle)),
    ]),
    _vm.field.customFieldDescription
      ? _c("h6", { staticClass: "col-12 text-secondary" }, [
          _vm._v(_vm._s(_vm.field.customFieldDescription)),
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "col-12 mb-3" },
      [
        _vm.field.customFieldType === _vm.enums.CustomFieldType.Int
          ? _c("field-input-int", {
              attrs: { field: _vm.curentField },
              on: { changed: _vm.valueChanged },
            })
          : _vm.field.customFieldType === _vm.enums.CustomFieldType.SelectList
          ? _c("field-input-select", {
              attrs: { field: _vm.curentField },
              on: { changed: _vm.valueChanged },
            })
          : _vm.field.customFieldType ===
            _vm.enums.CustomFieldType.DecisionSelectList
          ? _c("field-input-select", {
              attrs: { field: _vm.curentField },
              on: { changed: _vm.valueChanged },
            })
          : _vm.field.customFieldType === _vm.enums.CustomFieldType.Bool
          ? _c("field-input-bool", {
              attrs: { field: _vm.curentField },
              on: { changed: _vm.valueChanged },
            })
          : _vm.field.customFieldType === _vm.enums.CustomFieldType.Color
          ? _c("field-input-color", {
              attrs: { field: _vm.curentField },
              on: { changed: _vm.valueChanged },
            })
          : _vm.field.customFieldType === _vm.enums.CustomFieldType.Date
          ? _c("field-input-date", {
              attrs: { field: _vm.curentField },
              on: { changed: _vm.valueChanged },
            })
          : _c("field-input-text", {
              attrs: { field: _vm.curentField },
              on: { changed: _vm.valueChanged },
            }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "buttons col-12 d-flex mb-3 justify-content-between" },
      [
        _c(
          "b-button",
          {
            on: {
              click: function ($event) {
                return _vm.close()
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("shared.close")))]
        ),
        _c(
          "b-button",
          {
            staticClass: "ml-2",
            attrs: { variant: "success" },
            on: {
              click: function ($event) {
                return _vm.submit()
              },
            },
          },
          [_vm._v(" " + _vm._s(_vm.$t("shared.save")) + " ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }