






















import { Vue, Component, Prop } from 'vue-property-decorator';
import { AvailableValue, CustomFieldAnswer } from '../../models/custom-field-answer.model';

@Component
export default class CustomFieldInputSelect extends Vue {
    @Prop()
    field!: CustomFieldAnswer;

    private selectedGuid: string  | null = null;

    public get value() : any | null{
        if (this.field.guidValue !== null) {
            return this.options.find(x => x.guid === this.field.guidValue);
        }

        if (this.selectedGuid !== null) {
            return this.options.find(x => x.guid === this.selectedGuid);
        }

        return null;
    }

    public set value(val: any | null) {
        this.selectedGuid = val?.guid;
        this.$emit('changed', val?.guid.toString());
    }

    get options() : AvailableValue[] {
        return this.field.selectListAvailableValues;
    }
}
