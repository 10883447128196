




import { Component, Vue, Prop } from 'vue-property-decorator';
import { CustomFieldAnswer } from '../../models/custom-field-answer.model';

@Component
export default class CustomFieldInputDate extends Vue {

    @Prop()
    field!: CustomFieldAnswer;

    public get value() : any | null {
        return this.field.datetimeValue;
    }

    public set value(v: any | null) {
        this.$emit('changed', v?.toString());
    }

    private dateChanged(val: string) : void {
        const parsed = val += 'T00:00:00Z';
        this.value = parsed;
    }
}
