var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col" }, [
    _c("div", { staticClass: "profile-container mt-3" }, [
      _c("div", { staticClass: "rounded bg-white border px-3 pt-3 pb-2" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12 member-name" }, [
            _c("h5", [
              _vm._v(_vm._s(_vm.memberName) + " (" + _vm._s(_vm.gender) + ")"),
            ]),
          ]),
          _c("div", { staticClass: "col-sm-12 member-number" }, [
            _vm.currentMember.clubMembershipNumber
              ? _c("div", [
                  _c("strong", { staticClass: "label" }, [
                    _vm._v(
                      _vm._s(_vm.$t("profile.clubMembershipNumber")) + ": "
                    ),
                  ]),
                  _c("span", [
                    _vm._v(_vm._s(_vm.currentMember.clubMembershipNumber)),
                  ]),
                ])
              : _vm._e(),
            _vm.currentMember.federationMembershipNumber
              ? _c("div", [
                  _c("strong", { staticClass: "label ml-2" }, [
                    _vm._v(
                      _vm._s(_vm.$t("profile.federationMembershipNumber")) +
                        ": "
                    ),
                  ]),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.currentMember.federationMembershipNumber)
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "col-sm-12" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-sm-12 col-md-4 mt-2 mb-2 avatar" },
                [
                  _vm.avatarUrl == ""
                    ? _c("div", { staticClass: "empty-avatar-container" }, [
                        _c("i", { staticClass: "fas fa-user" }),
                      ])
                    : _c("div", { staticClass: "avatar-container" }, [
                        _c("img", {
                          staticStyle: { "max-width": "180px" },
                          attrs: { src: _vm.avatarUrl },
                        }),
                      ]),
                  _c("div", [
                    _c("i", { staticClass: "fas fa-birthday-cake" }),
                    _vm._v(" " + _vm._s(_vm.memberBirthDate) + " "),
                  ]),
                ]
              ),
              _c("div", { staticClass: "row col-sm-12 col-md-8" }, [
                _c("div", { staticClass: "col-sm-12 col-md-6" }, [
                  _c(
                    "div",
                    { staticClass: "mt-2 mb-2" },
                    [
                      _c("label", [
                        _c("strong", { staticClass: "label" }, [
                          _vm._v(_vm._s(_vm.$t("profile.emails"))),
                        ]),
                      ]),
                      _vm._l(_vm.currentMember.emails, function (item) {
                        return _c(
                          "div",
                          { key: item.email.id, staticClass: "inline-center" },
                          [
                            _c("span", [
                              _vm._v(_vm._s(item.email.emailAddress)),
                            ]),
                            item.email.secret
                              ? _c("i", {
                                  staticClass: "fas small fa-lock ml-1",
                                  attrs: { title: _vm.$t("profile.isSecret") },
                                })
                              : _vm._e(),
                            item.email.useForInvoicing
                              ? _c("i", {
                                  staticClass:
                                    "fas small fa-fw fa-file-invoice ml-1",
                                  attrs: {
                                    title: _vm.$t("profile.isForInvoicing"),
                                  },
                                })
                              : _vm._e(),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                  _c(
                    "div",
                    { staticClass: "mt-2 mb-2" },
                    [
                      _c("label", [
                        _c("strong", { staticClass: "label" }, [
                          _vm._v(_vm._s(_vm.$t("profile.phones"))),
                        ]),
                      ]),
                      _vm._l(_vm.currentMember.phones, function (item) {
                        return _c(
                          "div",
                          { key: item.phone.id, staticClass: "inline-center" },
                          [
                            _c("span", [
                              _vm._v(_vm._s(item.phone.phoneNumber)),
                            ]),
                            item.phone.secret
                              ? _c("i", {
                                  staticClass: "fas small fa-lock ml-1",
                                  attrs: { title: _vm.$t("profile.isSecret") },
                                })
                              : _vm._e(),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _c("div", { staticClass: "col-sm-12 col-md-6" }, [
                  _c(
                    "div",
                    { staticClass: "mt-2 mb-2" },
                    [
                      _c("label", [
                        _c("strong", { staticClass: "label" }, [
                          _vm._v(_vm._s(_vm.$t("profile.address"))),
                        ]),
                      ]),
                      _vm._l(_vm.currentMember.addresses, function (item) {
                        return _c(
                          "div",
                          { key: item.address.id },
                          _vm._l(
                            item.address.composition,
                            function (comp, index) {
                              return _c(
                                "span",
                                { key: item.address.id + "-" + index },
                                [_vm._v(_vm._s(comp)), _c("br")]
                              )
                            }
                          ),
                          0
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _c("div", { staticClass: "col-sm-12 text-right" }, [
                  _c(
                    "a",
                    {
                      staticClass:
                        "btn btn-link btn-sm ml-auto mt-n1 text-right",
                      on: {
                        click: function ($event) {
                          _vm.showCustomFields = !_vm.showCustomFields
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("profile.showCustomFields")))]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm.showCustomFields
      ? _c("div", { staticClass: "mt-3" }, [
          _c("div", { staticClass: "rounded bg-white border px-3 pt-3 pb-2" }, [
            _c("div", { staticClass: "row" }, [_c("custom-fields")], 1),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }