




import { Component, Vue, Prop } from 'vue-property-decorator';
import { CustomFieldAnswer } from '../../models/custom-field-answer.model';

@Component
export default class CustomFieldInputColor extends Vue {

    @Prop()
    field!: CustomFieldAnswer;

    public get value() : boolean {
        return this.field.boolValue ?? false;
    }

    public set value(v: boolean) {
        this.$emit('changed', v ? 'True' : 'False');
    }
}
