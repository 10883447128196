var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col-sm-12" },
    [
      _c("h6", [_vm._v(_vm._s(_vm.$t("profile.customFields.title")))]),
      _vm._l(_vm.groups, function (group) {
        return _c("div", { key: group.id, staticClass: "table-responsive" }, [
          _c("table", { staticClass: "table table-hover bg-white border" }, [
            _c(
              "thead",
              { staticClass: "small", attrs: { role: "rolegroup" } },
              [
                _c("tr", { attrs: { role: "row" } }, [
                  _c(
                    "th",
                    {
                      staticClass: "col-sm-5",
                      attrs: { role: "columnheader", scope: "col" },
                    },
                    [
                      _c("span", [_c("strong", [_vm._v(_vm._s(group.name))])]),
                      _c("span", [
                        _vm._v(" " + _vm._s(group.groupDescription)),
                      ]),
                    ]
                  ),
                  _c("th", {
                    staticClass: "col-sm-5",
                    attrs: { role: "columnheader", scope: "col" },
                  }),
                  _c("th", {
                    staticClass: "col-sm-2",
                    attrs: { role: "columnheader", scope: "col" },
                  }),
                ]),
              ]
            ),
            _c(
              "tbody",
              { attrs: { role: "rolegroup" } },
              _vm._l(_vm.answersInGroup(group.id), function (answer) {
                return _c(
                  "tr",
                  { key: answer.customFieldId, attrs: { role: "row" } },
                  [
                    _c(
                      "td",
                      {
                        staticClass: "col-sm-5 small",
                        attrs: { role: "cell", "aria-colindex": "1" },
                      },
                      [
                        _vm._v(_vm._s(answer.customFieldTitle)),
                        answer.customFieldDescription
                          ? [_vm._v(_vm._s(answer.customFieldDescription))]
                          : _vm._e(),
                      ],
                      2
                    ),
                    _vm.getDisplayValue(answer)
                      ? _c(
                          "td",
                          {
                            staticClass: "col-sm-5 small text-center",
                            attrs: { role: "cell", "aria-colindex": "2" },
                          },
                          [_vm._v(_vm._s(_vm.getDisplayValue(answer)))]
                        )
                      : _vm._e(),
                    _c(
                      "td",
                      {
                        staticClass: "col-sm-2 small text-right",
                        attrs: { role: "cell", "aria-colindex": "3" },
                      },
                      [
                        answer.canEdit
                          ? [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "btn btn-link btn-sm ml-auto mt-n1",
                                  on: {
                                    click: function ($event) {
                                      return _vm.showEditModal(answer)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("profile.customFields.edit"))
                                  ),
                                ]
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                )
              }),
              0
            ),
          ]),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }