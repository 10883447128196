




import { Component, Vue, Prop } from 'vue-property-decorator';
import { CustomFieldAnswer } from '../../models/custom-field-answer.model';

@Component
export default class CustomFieldInputColor extends Vue {

    @Prop()
    field!: CustomFieldAnswer;

    public get value() : any | null {
        return this.field.textValue;
    }

    public set value(v: any | null) {
        this.$emit('changed', v?.toString());
    }
}
