



























import { ToastHelper } from '@/shared/helpers/toast.helper';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { CustomFieldAnswer, CustomFieldType } from '../../models/custom-field-answer.model';
import CustomFieldInputInt from './CustomFieldInputInt.vue';
import CustomFieldInputSelect from './CustomFieldInputSelect.vue';
import CustomFieldInputDate from './CustomFieldInputDate.vue';
import CustomFieldInputText from './CustomFieldInputText.vue';
import CustomFieldInputColor from './CustomFieldInputColor.vue';
import CustomFieldInputBool from './CustomFieldInputBool.vue';
import { Action } from 'vuex-class';

@Component({
    components: {
        'field-input-int': CustomFieldInputInt,
        'field-input-select': CustomFieldInputSelect,
        'field-input-text': CustomFieldInputText,
        'field-input-date': CustomFieldInputDate,
        'field-input-color': CustomFieldInputColor,
        'field-input-bool': CustomFieldInputBool
    }
})
export default class EditCustomFieldValueModal extends Vue {
    @Prop()
    field!: CustomFieldAnswer;

    @Action
    private updateCustomFieldAnswer!: (payload: { fieldId: string, value: string | null }) => Promise<void>

    private loading = false;
    private selectedValue: string | null = null;

    private enums: any = {
        CustomFieldType
    }

    get curentField() : CustomFieldAnswer {
        return this.field;
    }

    created() : void {
        this.selectedValue = this.field.valueAsString;
    }

    valueChanged(value: string) {
        this.selectedValue = value;
    }

    async submit() : Promise<void> {
        try {
            this.loading = true;

            await this.updateCustomFieldAnswer({ 
                fieldId: this.curentField.customFieldId, 
                value:  this.selectedValue
            });

            this.$emit('close', { success: true });
        }
        catch (er) {
            ToastHelper.showError(this.$bvToast, this.$t('shared.toast.error'));
        }
        finally {
            this.loading = false;
        }
    }
    
    close(): void {
        this.$emit('close');
    }
}
