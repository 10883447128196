





























































































import { Member } from '@/modules/login/models/member.model';
import { DateHelper } from '@/shared/helpers/date.helper';
import { Vue, Component }  from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import CustomFields from './components/CustomFields.vue';
import { ToastHelper } from '@/shared/helpers/toast.helper';

@Component({
    components: {
        'custom-fields': CustomFields
    }
})
export default class Profile extends Vue {

    private showCustomFields = false;

    @Action
    private loadCustomFieldAnswers!: () => Promise<void>

    @Getter
    currentMember!: Member;

    async created() {
        await this.loadCustomFields();
    }

    private async loadCustomFields() : Promise<void> {
        try {
            await this.loadCustomFieldAnswers();
        }
        catch {            
            ToastHelper.showError(this.$bvToast, this.$t('shared.toast.error'));
        }
    }

    get gender() : string {
        return this.currentMember.gender.charAt(0).toUpperCase()
    }

    get memberBirthDate() : string {
        return DateHelper.getDate(this.currentMember.dateOfBirth);
    }

    get memberName() : string {
        let name = this.currentMember.firstName;

        if (this.currentMember.middleName) {
            name += ` ${this.currentMember.middleName}`
        }

        name += ` ${this.currentMember.lastName}`;

        return name.trim();
    }

    get avatarUrl() : string {
        if (this.currentMember?.assets[0]?.avatar.url) {
            return this.currentMember?.assets[0]?.avatar.url;
        }

        return '';
    }
}
